import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { ESurveyType } from "../../enums/survey-type.enum";

@Injectable()
export class SurveyService {
  private apiPrefix = '/client/survey'

  constructor(
    private api: ApiService
  ) {
  }

  getSurveys() {
    return this.api.get(`${this.apiPrefix}`);
  }

  getActiveSurveyWithType(type: ESurveyType) {
    return this.api.get(`${this.apiPrefix}/${type}`);
  }

  saveSurveyAnswers(id: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${id}`, data);
  }
}
